import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
import './assets/fonts/iconfont.js'

Vue.config.productionTip = false

Vue.prototype.$http = axios
axios.defaults.baseURL = 'https://shudb.cn:9002/'
axios.interceptors.request.use(config => {
  config.headers.token = window.localStorage.getItem('token')

  let url = config.url
  if (config.method === 'get' && config.params) {
    url += '?' // 拼接参数
    // 获取所有参数，通过循环 拼接所有参数，encodeURIComponent对参数编码，
    Object.keys(config.params).map(key => {
      url += `${key}=${encodeURIComponent(config.params[key])}&`
    })
    url = url.substring(0, url.length - 1) // 删除最后一个&字符
    config.params = {} // 参数已经存在于 url中
  }
  config.url = url

  return config
})

Vue.prototype.isLogin = function (data) {
  if (data.code === 2) {
    window.localStorage.clear()
    window.sessionStorage.clear()
    this.$router.push('/login')
    return false
  }
  return true
}
Vue.prototype.netError = '请求异常！'
Vue.prototype.baseURL = 'https://shudb.cn:9002/'
Vue.prototype.codeURL = 'https://shudb.cn:9002/paycode/'
Vue.prototype.imageURL = 'https://shudb.cn:9002/payimg/'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
