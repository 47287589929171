import Vue from 'vue'
import Router from 'vue-router'

const Login = () => import(/* webpackChunkName: "login" */'../components/Login')
const Home = () => import(/* webpackChunkName: "home" */'../components/Home')
const Book = () => import(/* webpackChunkName: "book" */'../components/Book')
const Advance = () => import(/* webpackChunkName: "advance" */'../components/Advance')
const Product = () => import(/* webpackChunkName: "product" */'../components/Product')
const Cart = () => import(/* webpackChunkName: "cart" */'../components/Cart')
const Error = () => import(/* webpackChunkName: "error" */'../components/Error')
const Order = () => import(/* webpackChunkName: "order" */'../components/Order')
const ComplateOrder = () => import(/* webpackChunkName: "complateorder" */'../components/ComplateOrder')
const Detail = () => import(/* webpackChunkName: "detail" */'../components/Detail')
const Back = () => import(/* webpackChunkName: "back" */'../components/Back')
const ComplateBack = () => import(/* webpackChunkName: "complateback" */'../components/ComplateBack')
const AddBack = () => import(/* webpackChunkName: "addback" */'../components/AddBack')
const OrderGet = () => import(/* webpackChunkName: "orderget" */'../components/OrderGet')
const BackGet = () => import(/* webpackChunkName: "backget" */'../components/BackGet')
const PayGet = () => import(/* webpackChunkName: "payget" */'../components/PayGet')
const Seller = () => import(/* webpackChunkName: "seller" */'../components/Seller')
const User = () => import(/* webpackChunkName: "user" */'../components/User')
const SellerAccount = () => import(/* webpackChunkName: "selleraccount" */'../components/SellerAccount')
const SystemAccount = () => import(/* webpackChunkName: "systemaccount" */'../components/SystemAccount')
const SystemBookAccount = () => import(/* webpackChunkName: "systembookaccount" */'../components/SystemBookAccount')

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      component: Login
    },
    {
      name: 'home',
      path: '/home',
      component: Home,
      redirect: '/book',
      children:
        [
          {
            name: 'book',
            path: '/book',
            component: Book
          },
          {
            name: 'advance',
            path: '/advance',
            component: Advance
          },
          {
            name: 'product',
            path: '/product',
            component: Product
          },
          {
            name: 'cart',
            path: '/cart',
            component: Cart
          },
          {
            name: 'error',
            path: '/error',
            component: Error
          },
          {
            name: 'order',
            path: '/order',
            component: Order
          },
          {
            name: 'complateorder',
            path: '/complateorder',
            component: ComplateOrder
          },
          {
            name: 'detail',
            path: '/detail',
            component: Detail
          },
          {
            name: 'back',
            path: '/back',
            component: Back
          },
          {
            name: 'complateback',
            path: '/complateback',
            component: ComplateBack
          },
          {
            name: 'addback',
            path: '/addback',
            component: AddBack
          },
          {
            name: 'orderget',
            path: '/orderget',
            component: OrderGet
          },
          {
            name: 'backget',
            path: '/backget',
            component: BackGet
          },
          {
            name: 'payget',
            path: '/payget',
            component: PayGet
          },
          {
            name: 'seller',
            path: '/seller',
            component: Seller
          },
          {
            name: 'user',
            path: '/user',
            component: User
          },
          {
            name: 'selleraccount',
            path: '/selleraccount',
            component: SellerAccount
          },
          {
            name: 'systemaccount',
            path: '/systemaccount',
            component: SystemAccount
          },
          {
            name: 'systembookaccount',
            path: '/systembookaccount',
            component: SystemBookAccount
          }
        ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = '书店宝 | 网店端'
  if (to.path === '/login') {
    if (window.localStorage.getItem('token')) {
      return next('/home')
    }
    return next()
  }
  if (!window.localStorage.getItem('token')) {
    return next('/login')
  }
  next()
})

export default router
