import Vue from 'vue'
import {
  Container, Header, Main, Footer,
  Menu, MenuItem, Submenu,
  Breadcrumb, BreadcrumbItem,
  Form, FormItem,
  Button, Input, Link, Select, Option, DatePicker, Switch,
  RadioGroup, RadioButton, InputNumber, Checkbox, Badge,
  Table, TableColumn, Pagination, Divider,
  Upload, Tooltip, Dialog, Popover, Card, Tag, Image, Popconfirm, Drawer,
  Message, Loading
} from 'element-ui'

Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Input)
Vue.use(Link)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(Badge)
Vue.use(Card)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Divider)
Vue.use(Popover)
Vue.use(Popconfirm)
Vue.use(Tooltip)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(Tag)
Vue.use(Image)
Vue.use(Drawer)
Vue.use(Loading)

Vue.prototype.loading = function () {
  const loading = this.$loading({
    lock: true,
    background: 'rgba(255, 255, 255, 0)'
  })
  setTimeout(function () {
    loading.close()
  }, 30000)
  return loading
}

let messageInstance = null
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
}
['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    options.duration = 3000
    return resetMessage(options)
  }
})
Vue.prototype.$msg = resetMessage
